import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowIconComponent } from './arrow-icon.component'


@NgModule({
  declarations: [
    ArrowIconComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[ArrowIconComponent]
})
export class ArrowIconModule { }

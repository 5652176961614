import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-arrow-icon',
  templateUrl: './arrow-icon.component.html',
  styleUrl: './arrow-icon.component.scss'
})
export class ArrowIconComponent {
  @Input() direction: 'RIGHT' | 'LEFT' | 'UP' | 'DOWN' = 'RIGHT';

}

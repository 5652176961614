<div class="arrow" [ngClass]="{
    'right-arrow': direction === 'RIGHT',
    'left-arrow': direction === 'LEFT',
    'up-arrow': direction === 'UP',
    'down-arrow': direction === 'DOWN'
  }">
    <svg  [ngSwitch]="direction" fill="#E4E7EB" width="101" height="100" viewBox="0 0 101 100"  xmlns="http://www.w3.org/2000/svg" id="arrow">
     <g style="mix-blend-mode:multiply">
      <!-- Right Arrow -->
        <path  [ngClass]="['arrow-path', 'right-arrow']" *ngSwitchCase="'RIGHT'" d="M58.9336 73.5576L54.5427 69.0384L70.4562 53.1249H18.3087V46.8751H70.4562L54.5427 30.9615L58.9336 26.4424L82.4912 50L58.9336 73.5576Z" fill="#E4E7EB"/>
      
      <!-- Left Arrow -->
        <path *ngSwitchCase="'LEFT'"d="M41.4664 26.4424L45.8573 30.9616L29.9438 46.8751H82.0912V53.1249H29.9438L45.8573 69.0385L41.4664 73.5576L17.9088 50L41.4664 26.4424Z" fill="#E4E7EB"/>
        
      <!-- Up Arrow -->
      <path *ngSwitchCase="'UP'" d="M15.707 9.707a1 1 0 0 1-1.414 0L13 8.414V18a1 1 0 0 1-2 0V8.414L9.707 9.707a1 1 0 0 1-1.414-1.414l3-3a1 1 0 0 1 1.416 0l3 3a1 1 0 0 1-.002 1.414z"/>
      
      <!-- Down Arrow -->
      <path *ngSwitchCase="'DOWN'" d="M10.293 16.293 9 17.586V4a1 1 0 0 0-2 0v13.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.416 0l3-3a1 1 0 0 0-1.414-1.414" />

     </g>
    </svg>
  </div>